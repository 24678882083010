body {
  margin: 0;
  background-color: #edf1f7;
  color: #222b45;
  font-family: Montserrat,sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
    color: #222b45;
    box-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 10%);
    height: 2.25rem;
    padding: 1.25rem;
}

div.layout-header nav {
    align-items: center;
    justify-content: flex-start;
    display: flex;
}

div.layout-header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1040;
  background-color: #fff;
  color: #222b45;
}

.layout-header {
  background-color: #edf1f7;
  color: #222b45;
  font-family: Montserrat,sans-serif;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.layout-header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header-container {
    display: flex;
    align-items: center;
    width: auto;
    height:40px;
}


.header-container .sidebar-toggle {
  color: #8f9bb3;
  padding-right: 1.25rem;
  text-decoration: none;
  display: none;
}

.header-container .width220  {
  display: none;
}

.sidebar-icon {
  font-size: 1.75rem;
  line-height: 1;
  width: 1em;
  height: 1em;
}

.layout-header .logo {
    height: 49px;
    width: 70%;
}

.logo-container {
    display: flex;
    align-items: center;
    width: calc(15rem - 1.25rem);
}

.user-container {
    position: relative;
    display: flex;
    align-items: center;
    font-size: .75rem;
    height: 1.5rem;
    padding: 0 1.25rem;
    width: 8rem;
}

.user-picture {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  position: relative;
  flex-shrink: 0;
  background-color: transparent;
  border: 1px solid #edf1f7;
  cursor: pointer;
}

.user-name {
  color: #222b45;
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: .9375rem;
  line-height: 1.25rem;
}

.unauthenticated-block {
    font-family: Montserrat,sans-serif;
    font-size: 1.0rem;
    font-weight: 600;
    line-height: 1.5rem;
    color:#555;
}

.unauthenticated-block .center-logo {
  height: 13.2rem;
  width: 50rem;
  position: relative;
  flex-shrink: 0;
  background-color: transparent;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0.1;
}

.unauthenticated-block .sign-in-message {
  margin: 0;
  margin-top: 4.0rem;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}


.info-container {
  margin-left: 0.5rem;
  cursor:pointer;
}

.select-button {
  padding-right: 2rem;
  padding: 0.4375rem 1rem;
  background-color: #f7f9fc;
  border-color: #36f;
  color: #222b45;
  transition-duration: 0.15s;
  transition-property: background-color,border-color,border-radius,box-shadow,color;
  transition-timing-function: ease-in;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.25rem;
  font-size: .9375rem;
  font-weight: 600;
  line-height: 1.5rem;
  min-width: 6rem;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
}

.select-icon {
  right: 0.2em;
  color: #8f9bb3;
  font-size: 1.5em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease-in;
  line-height: 1;
  width: 1em;
  height: 1em;
}

.select-icon svg {
  vertical-align: top;
}

.width220 {
  width: 220px;
}

.layout-container {
  padding-top: 4.75rem;
  min-height: 100vh;
  display: flex;
  flex: 1;
  -ms-flex: 1 1 auto;
  flex-direction: row;
}

.menu-sidebar {
  background-color: #fff;
    box-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 10%);
    color: #222b45;
    font-family: Montserrat,sans-serif;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.25rem;
    width: 14rem;
    display: none;
}

.menu-sidebar.expanded {
  width: 14rem;
}

.menu-sidebar.compacted {
  width: 4.5rem;
}

.menu-sidebar.compacted .menu-title {
  display: none;
}

.main-container {
  height: calc(100vh - 4.75rem);
  top: 4.75rem;
  position: fixed;
  transform: translate3d(0, 0, 0);
    display: flex;
    flex-direction: column;
}

.scrollable {
      padding-top: 2.25rem;
      display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    padding: 1.25rem;
    position: relative;
    -webkit-transform: translateZ(0);
}

.menu {
  margin: 0 -1.25rem -1.25rem;
  display: block;
  background-color: transparent;
}

.menu-items {
  list-style-type: none;
  overflow: hidden;
}

.menu ul.menu-items {
    margin: 0;
    padding: 0;
    transition: height 2s;
}

.menu .menu-item {
  border-bottom: 1px solid #edf1f7;
}

.menu-item a.disabled {
  pointer-events: none;
  color: #888;
  cursor: default;
}

.menu-item a.enabled {
  cursor: pointer;
}

li {
  color: #222b45;
    font-family: Montserrat,sans-serif;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.25rem;
}

li.menu-item {
  margin-bottom: 0.1rem;
}

.menu-item a.active {
    background-color: transparent;
    color: #36f;
}

.menu .menu-item a {
    font-family: Montserrat,sans-serif;
    font-size: .8125rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 0.75rem 1rem;
    display: flex;
    text-decoration: none;
    color: #222b45;
}

.menu .menu-item a:hover {
    background-color: transparent;
    border-color: transparent;
    color: #598bff;
}

.menu-item .menu-icon {
    color: #8f9bb3;
    font-size: 1.25rem;
    margin: 0 0.5rem 0 0;
    width: 1em;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1;
    width: 1em;
    height: 1em;
}

.menu .menu-item a.active .menu-icon {
    color: #36f;
}

.menu-icon svg {
    vertical-align: top;
}

 .menu-item a .menu-title {
    flex: 1 0 auto;
}

.menu-items.collapsed {
  height: 0px;
  margin: 0px;
}

.menu-sidebar.compacted .logo {
  display: none;
}

.menu a.collapsed .chevron-icon svg {
  transform: rotate(90deg);
}

.content {
    display: flex;
    flex: 1;
    -ms-flex: 1 1 auto;
    flex-direction: column;
    min-width: 0;
}

.chevron-icon {
    font-size: 1.25rem;
    line-height: 1;
    width: 1em;
    height: 1em;
}

.menu .menu-item>.menu-items>.menu-item a {
    border: 0 solid transparent;
    padding: 0.75rem 2rem;
}

.menu-sidebar .main-container .scrollable .logo {
    height: 30px;
    width: 110px;
}

.menu-item>.menu-items>.menu-item a:hover .menu-icon {
    color: #598bff;
}

.menu .menu-item a:hover .menu-icon {
  color: #598bff;
}

.header-container .context-menu {
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 10%);
  z-index: 1;
  top:40px;
  border-radius: 0.25rem;
}

.header-container .context-menu.open {
  display: block;
}

.header-container .context-menu.closed {
  display: none;
}
.menu-item a:hover {
    color: #598bff;
}

#tle {
  height: 100%;
}
  
